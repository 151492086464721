/* eslint-disable */
import React, { useEffect, useState, useContext } from 'react'
import { JsdcContext, JsdcMapViewer } from '../components/JsdcViewer'
import './JsdcMap.scss'
import geoJsonTrees from '../assets/trees.json'
import * as Cesium from 'cesium'
import {
  Cartesian3,
  Entity as CesiumEntity,
  Viewer as CesiumViewer
} from 'cesium'
import { ITreeAttribute } from '../bigTreeCompnt/Popup/AttributeTable'
import Popup from '../bigTreeCompnt/Popup'
// import Popup from './bigTreeCompnt/Popup'
import ImageViewer from 'react-simple-image-viewer'
import FileUpload from '../bigTreeCompnt/FileUpload'
import ExpandContainer from '../bigTreeCompnt/ExpandContainer'
import Expand from '../bigTreeCompnt/ExpandContainer/Expand'
import ExpandPanel from '../bigTreeCompnt/ExpandContainer/ExpandPanel'
import { usePanelStates } from '../hooks'
import QueryPanel from '../bigTreeCompnt/QueryPanel'
import ResultPanel from '../bigTreeCompnt/ResultPanel'
import baseMapBtnIcon from '../assets/layer.png'
import infoIcon from '../assets/tools-info.svg'

const accept = '.kml'

const JsdcMap = () => {
  const [images, setimages] = useState<string[]>([])
  const [currentImage] = useState(0)
  const [isViewerOpen, setIsViewerOpen] = useState(false)
  const [popupOpen, setpopupOpen] = useState<boolean>(false)
  const [treeAttribute, settreeAttribute] = useState<ITreeAttribute>()
  const [liDARImage, setliDARImage] = useState<string | undefined>(undefined)
  const [youtubeLink, setyoutubeLink] = useState<string>('')
  const [sharedLink, setsharedLink] = useState<string>('')
  const panelStates = usePanelStates()
  const jsdc = useContext(JsdcContext)

  const insertInstruction = () => {

    const baseDiv = document.getElementsByClassName('cesium-button cesium-toolbar-button')[5] as HTMLDivElement
    const img = document.createElement('img') as HTMLImageElement
    img.src = baseMapBtnIcon
    baseDiv.appendChild(img)

    const infoDiv = document.getElementsByClassName('cesium-button cesium-toolbar-button cesium-navigation-help-button')[0] as HTMLDivElement
    const infoImg = document.createElement('img') as HTMLImageElement
    infoImg.src = infoIcon
    infoDiv.innerHTML = ''
    infoDiv.appendChild(infoImg)

    const instructionContainer = document.getElementsByClassName('cesium-navigation-help')[0] as HTMLDivElement
    // @ts-ignore
    instructionContainer.style.zIndex = 10
    instructionContainer.innerHTML = `
      <div class='instructionContainer'>
        <span class='title'>台灣巨木地圖</span>
        <span class='about'>
          樹高的測計向來是森林資源的調查上的重點之一,但樹高量測,尤其是針對50公尺以上的超高大樹,存在著本質上的困難, 本計畫利用空載光達資料來取得全台灣的森林樹冠高度模型,但台灣的地形陡峭, 簡單的樹高模型誤差極大,實務上很難應 用於原始森林的巨木探測。
        </span>
        <span class='about2'>
          從2018年以來,找樹的人團隊利用光達點雲原始資料加以處理成為多方位的樹形垂直剖面圖像檔,並借助網路群眾的能力, 合力判釋全島65公尺以上巨木的存在,經過4年來的努力,終於在全台灣定位出941 株樹高超過65公尺以上的巨木之潛在位點 ,本研究結合科學專業與群眾的力量來揭 曉台灣巨木的存在,對啟發國人的保育意識有莫大的助益。
          關於找樹的人
        </span>
        <div class='fbContainer'>
          <span>關於找樹的人</span>
          <div class='divider'></div>
          <a target="_blank" rel="noopener noreferrer" href='https://bit.ly/3VBXmkb'>找樹的人FB粉絲專頁</a>
          <img src='/icon/FB-pic.png'/>
        </div>
        <div class='newsContainer'>
          <span>媒體報導</span>
          <div class='divider'></div>
          <a target="_blank" rel="noopener noreferrer" href='https://bit.ly/3gNCPu8'>環境資源中心</a>
          <span>找尋百萬分之一的冠軍巨木</span>
        </div>
      </div>
    `
  }

  const hideDefaultUI = () => {
    const timeline = document.getElementsByClassName('cesium-viewer-timelineContainer')[0] as HTMLDivElement
    const mode = document.getElementsByClassName('cesium-sceneModePicker-wrapper cesium-toolbar-button')[0] as HTMLDivElement
    timeline.style.display = 'none'
    mode.style.display = 'none';
    (document.getElementsByClassName('cesium-viewer-geocoderContainer')[0] as HTMLDivElement).style.display = 'none';
    (document.getElementsByClassName('cesium-button cesium-toolbar-button cesium-home-button')[0] as HTMLDivElement).style.display = 'none'

    const layerTitle = document.getElementsByClassName('cesium-baseLayerPicker-sectionTitle')[0] as HTMLDivElement
    layerTitle.innerHTML = '地圖圖層'
  }

  const loadTrees = async () => {
    const viewer = await jsdc.asyncViewer
    Cesium.GeoJsonDataSource.clampToGround = true;
    const treeDatasource = await viewer.dataSources.add(
      Cesium.GeoJsonDataSource.load(geoJsonTrees, {
        clampToGround: true
      })
    )
    treeDatasource.name = 'treesDatasource'
    treeDatasource.clustering.enabled = true
    treeDatasource.clustering.pixelRange = 15
    treeDatasource.clustering.minimumClusterSize = 3
    treeDatasource.entities.values.forEach((entity) => {
      if (entity.billboard) {
        entity.billboard.image = new Cesium.ConstantProperty('/icon/number/1tree.svg')
      }
    })
    let removeListener: undefined | (() => void) = undefined
    const addClusterEffect = () => {
      if (removeListener) {
        removeListener();
        removeListener = undefined;
      } else {
        removeListener = treeDatasource.clustering.clusterEvent.addEventListener(
          function (clusteredEntities, cluster) {
            cluster.label.show = false
            cluster.billboard.show = true
            cluster.billboard.id = cluster.label.id
            cluster.billboard.verticalOrigin = Cesium.VerticalOrigin.BOTTOM
            cluster.billboard.heightReference = Cesium.HeightReference.CLAMP_TO_GROUND
            if (clusteredEntities.length >= 50) {
              cluster.billboard.image = '/icon/number/50_trees.svg'
            } else if (clusteredEntities.length >= 40) {
              cluster.billboard.image = '/icon/number/40_trees.svg'
            } else if (clusteredEntities.length >= 30) {
              cluster.billboard.image = '/icon/number/30_trees.svg'
            } else if (clusteredEntities.length >= 20) {
              cluster.billboard.image = '/icon/number/20_trees.svg'
            } else if (clusteredEntities.length >= 10) {
              cluster.billboard.image = '/icon/number/10_trees.svg'
            } else {
              cluster.billboard.image = '/icon/number/2-9_trees.svg'
            }
          }
        )
      }
      const pixelRange = treeDatasource.clustering.pixelRange;
      treeDatasource.clustering.pixelRange = 0;
      treeDatasource.clustering.pixelRange = pixelRange;
    }
    viewer.scene.globe.depthTestAgainstTerrain = true
    addClusterEffect()
  }

  const onFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const viewer = await jsdc.asyncViewer
    if (event.target.files === null) return
    const fileBlob = event.target.files[0]
    loadKmlDataSource(viewer, fileBlob)
  }

  const onFileDrop = async (event: React.DragEvent<HTMLElement>) => {
    const viewer = await jsdc.asyncViewer
    const fileBlob = event.dataTransfer.files[0]
    loadKmlDataSource(viewer, fileBlob)
  }

  const loadKmlDataSource = async (viewer: CesiumViewer, blob: File) => {
    const datasource = await viewer.dataSources.add(Cesium.KmlDataSource.load(URL.createObjectURL(blob),
      {
        camera: viewer.scene.camera,
        canvas: viewer.scene.canvas,
        clampToGround: true
      })
    );
    console.log(datasource)
    viewer.flyTo(datasource)
    for (let i = 0; i < datasource.entities.values.length; i++) {
      const polyline = datasource.entities.values[i].polyline
      if (polyline) {
        polyline.arcType = new Cesium.ConstantProperty(Cesium.ArcType.GEODESIC)
        polyline.clampToGround = new Cesium.ConstantProperty(true)
      }
      const point = datasource.entities.values[i].point
      if (point) {
        point.heightReference = new Cesium.ConstantProperty(Cesium.HeightReference.CLAMP_TO_GROUND)
      }
      const polygon = datasource.entities.values[i].polygon
      if (polygon) {
        polygon.heightReference = new Cesium.ConstantProperty(Cesium.HeightReference.CLAMP_TO_GROUND)
        polygon.arcType = new Cesium.ConstantProperty(Cesium.ArcType.GEODESIC)
      }
    }
  }

  const parseUrlParams = async () => {
    const viewer = await jsdc.asyncViewer
    const urlParams = new URLSearchParams(window.location.search)
    const id = urlParams.get('id')
    const x = urlParams.get('x')
    const y = urlParams.get('y')
    const z = urlParams.get('z')
    const heading = urlParams.get('heading')
    const pitch = urlParams.get('pitch')
    const height = urlParams.get('height')
    if (!id || !heading || !pitch || !height) { handleSetToTaiwan(); return }
    const treeDatasource = viewer.dataSources.getByName('treesDatasource')[0]
    const entity = treeDatasource.entities.getById(id)
    if (!entity || !entity.position) { alert('查無此樹'); return }
    const centerPosition: Cesium.Cartesian3 | undefined = undefined
    const julian = Cesium.JulianDate.now()
    const position = entity.position.getValue(julian, centerPosition)
    if (!position) { alert('查無此樹'); return }
    viewer.camera.setView({
      destination: new Cesium.Cartesian3(
        Number(x),
        Number(y),
        Number(z)
      ),
      orientation: {
        heading: Number(heading), // east, default value is 0.0 (north)
        pitch: Cesium.Math.toRadians(Number(pitch)),    // default value (looking down)
        roll: 0.0                             // default value
      }
    });
  }

  const handlePopupUpdate = async () => {
    const viewer = await jsdc.asyncViewer
    let removeOnTick: Cesium.Event.RemoveCallback | undefined = undefined
    viewer.selectedEntityChanged.addEventListener((entity: CesiumEntity) => {
      if (entity) {
        setpopupOpen(true)
        const selectedTree = geoJsonTrees.features.filter(t => t.properties['OBJECTID'] === Number(entity.id))
        if (selectedTree.at(0)) {
          const properties = selectedTree[0].properties
          settreeAttribute(
            {
              OBJECTID: properties.OBJECTID,
              New_ID: properties.New_ID,
              ID: Number(properties.ID),
              "名稱": properties['名稱'],
              "編號": properties['名稱'],
              "X座標": properties['X座標'],
              "Y座標": properties['Y座標'],
              "實際樹高": properties['實際樹高'],
              "樹高_LiDAR": properties['樹高_LiDAR'],
              "F50坡度": Number(properties['F50坡度']),
              "F20坡度": Number(properties['F20坡度']),
              "海拔_DEM_": Number(properties['海拔_DEM_']),
              "地點": properties['地點'] ? properties['地點'] : '',
              "產製年分": Number(properties['產製年分']),
              "備註": properties['備註'],
              Filename: properties.Filename ? properties.Filename : '',
              F3D_Animation_LINK: properties.F3D_Animation_LINK ? properties.F3D_Animation_LINK : '',
              longitude: selectedTree[0].geometry.coordinates[0],
              latitude: selectedTree[0].geometry.coordinates[1],
              COUNTYNAME: properties.COUNTYNAME,
              TOWNNAME: properties.TOWNNAME
            }
          )
          setliDARImage(
            properties.Filename
              ? `https://no1tree.tw/images/${properties.Filename}_profile.png`
              : undefined
          )
          setyoutubeLink(properties.F3D_Animation_LINK ? properties.F3D_Animation_LINK : '')
        }
      } else {
        if (removeOnTick) {
          setpopupOpen(false)
        }
      }
    })
  }

  const handleSharedLinkUpdate = async () => {
    const viewer = await jsdc.asyncViewer
    viewer.camera.moveEnd.addEventListener(function () {
      try {
        const cartographic = Cesium.Cartographic.fromCartesian(viewer.camera.position)
        const pitchDegree = Cesium.Math.toDegrees(viewer.camera.pitch)
        const height = cartographic.height
        setsharedLink(`&x=${viewer.camera.position.x}&y=${viewer.camera.position.y}&z=${viewer.camera.position.z}&heading=${viewer.camera.heading}&pitch=${pitchDegree}&height=${height}`)
      } catch {
        console.log('share link function disabled')
      }
    })
  }

  const handleTerrainLoad = async () => {
    const viewer = await jsdc.asyncViewer
    const provider = new Cesium.CesiumTerrainProvider({
      url: Cesium.IonResource.fromAssetId(1396736)
    })
    viewer.terrainProvider = provider
  }

  const handleSetToTaiwan = async () => {
    const viewer = await jsdc.asyncViewer
    // @ts-ignore
    window.cesium = Cesium
    // @ts-ignore
    window.viewer = viewer
    viewer.camera.flyTo({
      destination: new Cesium.Cartesian3(
        -3234837.395982461,
        5084370.488937034,
        2353821.538332212
      ),
      orientation: {
        heading: 5.6,
        pitch: -0.40005092039086687
      },
    })
  }

  useEffect(() => {
    (async () => {
      await handleTerrainLoad()
      await loadTrees()
      insertInstruction()
      hideDefaultUI()
      await handlePopupUpdate()
      await handleSharedLinkUpdate()
      await parseUrlParams()
    })()
  }, [])


  return (
    <div className='jsdcmap-root'>

      <div className='pic'>
        <img src='/bg/tree-logo_desktop_241205.png' className='basePic desktop' />
        <img src='/bg/tree-logo_mobile_241205.png' className='basePic mobile' />
      </div>

      <JsdcMapViewer disabledTimeline={true} toolbarItems={[]} disabledPopupInfobox={true}>
      </JsdcMapViewer>

      <ExpandContainer
        {...panelStates}
      >
        <Expand
          content='查詢'
          iconPath='/icon/add/tree-icon01.svg'
          expandType='query'
        />
        <Expand
          content='結果'
          iconPath='/icon/add/tree-icon02.svg'
          expandType='result'
        />
        {/* <Expand
          content='上傳KML'
          iconPath='/icon/upload.svg'
          expandType='upload'
        /> */}

        <ExpandPanel expandType='query'>
          <QueryPanel />
        </ExpandPanel>
        <ExpandPanel expandType='result'>
          <ResultPanel />
        </ExpandPanel>
        {/* <ExpandPanel expandType='upload'>
          <FileUpload
            accept={accept}
            onChange={onFileChange}
            onDrop={onFileDrop}
          />
        </ExpandPanel> */}

      </ExpandContainer>

      <Popup
        open={popupOpen}
        // transform={{
        //   transform: transformStyle,
        //   position: 'absolute',
        //   left: '0px',
        //   top: '0px'
        // }}
        onClose={() => {
          setpopupOpen(false)
        }}
        row={treeAttribute}
        liDARImage={liDARImage}
        youtubeLink={youtubeLink}
        sharedLink={sharedLink}
        onPhotoClick={(src) => {
          setimages([src])
          setIsViewerOpen(true)
        }}
      />
      {isViewerOpen && (
        <ImageViewer
          src={images}
          currentIndex={currentImage}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={() => {
            setIsViewerOpen(false)
          }}
        />
      )}

      {/* <Version version='b1.4.5' /> */}

    </div>
  )
}

export default JsdcMap
