/* eslint-disable */
import classNames from 'classnames'
import './index.scss'
import { ExpandType } from '../Expand'
import { useContext } from 'react'
import { PanelContext } from '../../../context'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

export interface IExpandPanel {
  children: React.ReactNode
  expandType: ExpandType
}

const ExpandPanel = ({
  children,
  expandType
}: IExpandPanel) => {
  const states = useContext(PanelContext)
  if (!states) return <></>
  const { selectedExpand, onExpandClick } = states

  return (
    <div className={classNames({
      'ExpandPanel': true,
      'show': selectedExpand === expandType,
      'hide': selectedExpand !== expandType
    })}>
      {children}
      {/* <IconButton color='error' component="label"
        sx={{ position: 'absolute', top: '-10px', right: '-10px' }}
        onClick={() => {
          onExpandClick('close')
        }}
      >
        <CloseIcon />
      </IconButton> */}
      
      <div className='closeBtn' onClick={() => {
          onExpandClick('close')
        }}>
      {/* <svg  id="_圖層_2" data-name="圖層_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
        <g id="Layer_1">
          <g>
            <path fill="#03a1fc" d="M11,1c5.51,0,10,4.49,10,10s-4.49,10-10,10S1,16.51,1,11,5.49,1,11,1M11,0C4.92,0,0,4.92,0,11s4.92,11,11,11,11-4.92,11-11S17.08,0,11,0h0Z"/>
            <g>
              <path fill="#03a1fc" d="M5.95,16.55c-.13,0-.26-.05-.35-.15-.2-.2-.2-.51,0-.71L15.7,5.6c.2-.2.51-.2.71,0s.2.51,0,.71l-10.1,10.1c-.1.1-.23.15-.35.15Z"/>
              <path fill="#03a1fc" d="M16.05,16.55c-.13,0-.26-.05-.35-.15L5.6,6.3c-.2-.2-.2-.51,0-.71s.51-.2.71,0l10.1,10.1c.2.2.2.51,0,.71-.1.1-.23.15-.35.15Z"/>
            </g>
          </g>
        </g>
      </svg> */}
        <img src='/close.png' className='basePic' />
      </div>



      
    </div>
  )
}

export default ExpandPanel
