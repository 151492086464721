/* eslint-disable */
import MUIDataTable from 'mui-datatables'
import Box from '@mui/material/Box'
import { useContext, useEffect, useState } from 'react'
import { PanelContext } from '../../context'
import geoJsonTrees from '../../assets/trees.json'
import round from '../../util/Round'
import Button from '@mui/material/Button'
import ClearAllIcon from '@mui/icons-material/ClearAll'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { EChartsOption } from 'echarts'
import ReactEcharts from 'echarts-for-react'
import { JsdcContext } from '../../components/JsdcViewer'

const columns = [
  {
    name: "id",
    label: "名稱",
    options: {
      filter: true,
      sort: false,
    }
  },
  {
    name: "height",
    label: "樹高_LiD",
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: "location",
    label: "地點",
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: "altitude",
    label: "海拔_DEM",
    options: {
      filter: true,
      sort: true,

    }
  },
]

export interface IResultTreeRow {
  id: string
  height: number
  location: string
  altitude: number
}

const ResultPanel = () => {
  const [resultTreeDatas, setresultTreeDatas] = useState<IResultTreeRow[]>([])
  const states = useContext(PanelContext)
  const [tab, settab] = useState<string>('0')
  const [graphYValue, setgraphYValue] = useState<number[]>([0, 0, 0, 0, 0, 0, 0])
  const jsdc = useContext(JsdcContext)

  const handleClearResults = async () => {
    if (!states) return
    const {
      onResultTreeIdsChange,
      onExpandClick
    } = states
    // const viewer = viewerRef.current?.cesiumElement
    // if (!viewer) return
    const viewer = await jsdc.asyncViewer
    viewer.entities.removeById('sketch_point')
    viewer.entities.removeById('sketch_buffer')
    const dataSource = viewer.dataSources.getByName('treesDatasource')[0]

    for (let i = 0; i < dataSource.entities.values.length; i++) {
      const entity = dataSource.entities.values[i]
      // if (!entity.id.includes('tree')) continue
      // const modifiableEntity = viewer.entities.getById(entity.id)
      // if (!modifiableEntity) continue
      entity.show = true
    }
    onResultTreeIdsChange([])
    onExpandClick('query')
  }

  const handleRowClick = async (rowData: string[], rowMeta: {
    dataIndex: number;
    rowIndex: number;
  }) => {
    const viewer = await jsdc.asyncViewer
    const id: string = rowData[0]
    const tree = geoJsonTrees.features.filter(t => t.properties['名稱'] === id)[0]
    const EntityId = tree.properties.OBJECTID.toString()
    const dataSource = viewer.dataSources.getByName('treesDatasource')[0]
    dataSource.entities.values.forEach((e) => {
      if (e.id.toString() === EntityId) {
        const entity = dataSource.entities.getById(e.id)
        if (entity) {
          viewer.flyTo(entity)
          return
        }
      }
    })

  }

  useEffect(() => {
    if (!states) return
    let newData: IResultTreeRow[] = []
    geoJsonTrees.features.forEach((t) => {
      if (states.resultTreeIds.includes(t.properties['OBJECTID'].toString())) {
        newData.push({
          id: t.properties['名稱'] ? t.properties['名稱'] : '',
          height: round(t.properties['樹高_LiDAR'], 2),
          location: t.properties['地點'] ? t.properties['地點'] : '',
          altitude: round(Number(t.properties['海拔_DEM_']), 2),
        })
      }
    })
    newData = newData.sort((a, b) => b.height - a.height)
    setresultTreeDatas(newData)
    setgraphYValue([
      newData.filter(t => t.height >= 64 && t.height < 68).length,
      newData.filter(t => t.height >= 68 && t.height < 71).length,
      newData.filter(t => t.height >= 71 && t.height < 74).length,
      newData.filter(t => t.height >= 74 && t.height < 77).length,
      newData.filter(t => t.height >= 77 && t.height < 80).length,
      newData.filter(t => t.height >= 80 && t.height < 83).length,
      newData.filter(t => t.height >= 83 && t.height < 1000).length
    ])
  }, [states?.resultTreeIds])

  const renderResultTable = () => {
    return (
      <TabPanel value="0" sx={{ padding: '0px', height:'630px', overflowY:'auto'}}>
        <MUIDataTable
          title='查詢結果'
          data={resultTreeDatas}
          columns={columns}
          options={{
            filterType: 'checkbox',
            rowsPerPage: 2,
            rowsPerPageOptions: [2],
            selectableRows: 'none',
            print: false,
            filter: false,
            onRowClick: handleRowClick
          }}
        />
        <Button
          variant='contained'
          startIcon={<ClearAllIcon />}
          onClick={handleClearResults}
          sx={{ marginTop: '12px', backgroundColor: '#00489A' }}
          fullWidth
        >清除</Button>
      </TabPanel>
    )
  }

  const renderStatisticGraph = () => {
    return (
      <TabPanel value="1" sx={{ paddingBottom: '0px' }}>
        <ReactEcharts option={{
          toolbox: {
            feature: {
              saveAsImage: { show: true }
            }
          },
          xAxis: {
            type: 'category',
            name: '樹高',
            data: ['64-', '68-', '71-', '74-', '77-', '80-', '83-'],
            axisPointer: {
              type: 'shadow'
            }
          },
          yAxis: {
            type: 'value',
            name: '數量',
            interval: 30,
            axisLabel: {
              formatter: '{value} 棵'
            }
          },
          series: [
            {
              data: graphYValue,
              type: 'bar',
              name: '數量',
              tooltip: {
                valueFormatter: function (value: string) {
                  return value + ' 棵';
                }
              }
            }
          ]
        }} />
      </TabPanel>
    )
  }

  return (
    <Box
      sx={{ width: '280px' }}
    >
      <TabContext value={tab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={(event: React.SyntheticEvent, newValue: string) => {
            settab(newValue)
          }}>
            <Tab label="結果列表" value="0" />
            {/* <Tab label="統計圖表" value="1" /> */}
          </TabList>
        </Box>
        {renderResultTable()}
        {/* {renderStatisticGraph()} */}
      </TabContext>

    </Box>
  )
}

export default ResultPanel
