/* eslint-disable */
import './index.scss'
import classNames from 'classnames'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { useState } from 'react'
import AttributeTable, { ITreeAttribute } from './AttributeTable'
import LiDAR from './LiDAR'
// import YouTube from 'react-youtube'
import React from 'react'
import { default as _ReactPlayer } from 'react-player/lazy';
import { ReactPlayerProps } from "react-player/types/lib";
const ReactPlayer = _ReactPlayer as unknown as React.FC<ReactPlayerProps>;


export interface IPopup {
  open: boolean
  // transform: React.CSSProperties
  row: ITreeAttribute | undefined
  liDARImage: string | undefined
  youtubeLink: string
  sharedLink: string
  onPhotoClick: (src: string) => void
  onClose: () => void
}

const Popup = ({
  open,
  // transform,
  row,
  liDARImage,
  youtubeLink,
  sharedLink,
  onPhotoClick,
  onClose
}: IPopup) => {
  const theme = useTheme()
  const isBreakPointHit = useMediaQuery(theme.breakpoints.up('sm'))
  const [value, setValue] = useState('1')

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  return (
    <div
      className={classNames({
        'Popup': true,
        // 'show': open
        'show': open,
        'hide': !open
      })}
    // style={isBreakPointHit ? transform : undefined}
    >

      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange}>
            <Tab label="基本資訊" value="1" />
            <Tab label="LiDAR" value="2" />
            <Tab label="線上影片" value="3" />
          </TabList>
        </Box>

        <TabPanel value="1" sx={{ padding: '0px', overflowY: 'auto' }} className='tabPanel'>
          <AttributeTable row={row} sharedLink={sharedLink} />
        </TabPanel>

        <TabPanel value="2">
          {
            liDARImage
              ? <LiDAR src={liDARImage} onClick={onPhotoClick} />
              : <p>無圖片</p>
          }

        </TabPanel>

        <TabPanel value="3">
          {/* <YouTube
            videoId={youtubeLink}
            // className='yt'
            opts={{
              width: '100%',
              height: '100%'
            }}
          /> */}

          <ReactPlayer
            url={youtubeLink}
            width='100%'
            height='100%'
          />
        </TabPanel>
      </TabContext>

      <div className='closeBtn' onClick={onClose}>
      {/* <svg  id="_圖層_2" data-name="圖層_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
        <g id="Layer_1">
          <g>
            <path fill="#03a1fc" d="M11,1c5.51,0,10,4.49,10,10s-4.49,10-10,10S1,16.51,1,11,5.49,1,11,1M11,0C4.92,0,0,4.92,0,11s4.92,11,11,11,11-4.92,11-11S17.08,0,11,0h0Z"/>
            <g>
              <path fill="#03a1fc" d="M5.95,16.55c-.13,0-.26-.05-.35-.15-.2-.2-.2-.51,0-.71L15.7,5.6c.2-.2.51-.2.71,0s.2.51,0,.71l-10.1,10.1c-.1.1-.23.15-.35.15Z"/>
              <path fill="#03a1fc" d="M16.05,16.55c-.13,0-.26-.05-.35-.15L5.6,6.3c-.2-.2-.2-.51,0-.71s.51-.2.71,0l10.1,10.1c.2.2.2.51,0,.71-.1.1-.23.15-.35.15Z"/>
            </g>
          </g>
        </g>
        </svg> */}
        
        
        <img src='/close.png' className='basePic' />
      </div>

    </div>
  )
}

export default Popup
